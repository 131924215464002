<template>
  <v-container>
    <v-row class="text-center" align="center">
      <v-col class="pa-6 pb-4" cols="12">
        <v-img
          :src="
            mobile
              ? require('../assets/popmysticLogo.png')
              : require('../assets/popmysticGraphic.svg')
          "
          class="my-3"
          contain
          height="150"
        />
      </v-col>

      <v-col cols="12" style="color: white; font-size: 1.2em">
        <p class="mb-0">
          Search for Any Word or Phrase from 225,000 Movies and TV Shows
        </p>
        <p>
          280 Million Quotes - The World’s Largest Movie and TV Quote Database
        </p>
      </v-col>

      <v-col class="pa-2" cols="12">
        <v-text-field
          class="searchData"
          v-on:keyup.enter="performSearch()"
          solo
          hide-details
          v-model="searchParams.searchPhrase"
          label="Search Phrase Movie & TV quotes"
          color="#5b5ea6"
        />
      </v-col>
      <v-col class="pa-2" cols="12">
        <v-btn
          type="submit"
          @click="performSearch()"
          class="black--text"
          color="secondary"
          style="min-width: 200px; min-height: 50px"
          >Search
        </v-btn>
      </v-col>

      <v-col class="pa-2" cols="12" sm="12" md="12" lg="12" xl="12">
        <v-text-field
          class="searchData"
          solo
          hide-details
          v-model="searchParams.title"
          label="Filter by Film, Episode, Series Title"
          v-on:keyup.enter="performSearch()"
        />
      </v-col>
      <v-col class="pa-2" cols="6" sm="4" md="4" lg="4" xl="4">
        <v-text-field
          class="searchData"
          solo
          hide-details
          label="FROM YYYY"
          v-model="searchParams.startYear"
          :rules="dateRules"
          type="number"
          v-on:keyup.enter="performSearch()"
        >
        </v-text-field>
      </v-col>
      <v-col class="pa-2" cols="6" sm="4" md="4" lg="4" xl="4">
        <v-text-field
          class="searchData"
          solo
          hide-details
          label="TO YYYY"
          v-model="searchParams.endYear"
          :rules="dateRules"
          type="number"
          v-on:keyup.enter="performSearch()"
        >
        </v-text-field>
      </v-col>

      <v-col class="pa-2" cols="12">
        <v-text-field
          class="searchData"
          solo
          hide-details
          v-model="searchParams.keyPerson"
          label="Search by Actor or Director"
          v-on:keyup.enter="performSearch()"
          >Key Person
        </v-text-field>
      </v-col>
      <v-col class="pa-2" cols="12" md="6" lg="6" xl="6">
        <v-combobox
          class="searchData"
          solo
          hide-details
          multiple
          label="Select Genres"
          :items="genres"
          v-model="searchParams.selectedGenres"
          item-color="#5b5ea6"
        ></v-combobox>
      </v-col>
      <v-col class="pa-2">
        <!-- <v-row justify="start"> -->
        <v-radio-group
          hide-details=""
          row
          v-model="searchParams.mediaType"
          style="color: white; margin-top: 0"
        >
          <v-radio class="whiteRadio" label="Movies" value="movie" />
          <v-radio class="whiteRadio" label="TV" value="tv" />
          <v-radio class="whiteRadio" label="Both" value="both" />
        </v-radio-group>
        <!-- </v-row> -->
      </v-col>
    </v-row>
    <hr />

    <h2>Top Quotes from Popular Movies</h2>
    <br />

    <v-row align="start" justify="center">
      <v-col cols="5" md="5" lg="3" xl="3">
        <a
          href="https://popmystic.com/searchResults?phrase=the%20force&title=wars&endYear=1985&mediaType=both"
        >
          <v-img style="max-width: 100%" src="../assets/Star Wars poster.png" />
        </a>
        <p class="whiteText centerText">Star Wars Quotes</p>
      </v-col>
      <v-col cols="5" md="5" lg="3" xl="3">
        <a
          href="https://popmystic.com/movieDetails?film=tt0377092&phrases=tt0377092-919,tt0377092-920,tt0377092-677,tt0377092-681,tt0377092-1211,tt0377092-321,tt0377092-1363,tt0377092-1056,tt0377092-226,tt0377092-463,tt0377092-770"
        >
          <v-img
            style="max-width: 100%"
            src="../assets/Mean Girls poster.png"
          />
        </a>
        <p class="whiteText centerText">Mean Girls Quotes</p>
      </v-col>
      <v-col cols="5" md="5" lg="3" xl="3">
        <a
          href="https://popmystic.com/movieDetails?film=tt10515718&phrases=tt10515718-73,tt10515718-606,tt10515718-157,tt10515718-103,tt10515718-42,tt10515718-775,tt10515718-620,tt10515718-696"
        >
          <v-img
            style="max-width: 100%"
            src="../assets/Bridgerton poster.png"
          />
        </a>
        <p class="whiteText centerText">Bridgerton Quotes</p>
      </v-col>
      <v-col cols="5" md="5" lg="3" xl="3">
        <a
          href="https://popmystic.com/movieDetails?film=tt0446029&phrases=tt0446029-1311,tt0446029-1337,tt0446029-1651,tt0446029-1306,tt0446029-916,tt0446029-1091,tt0446029-1502,tt0446029-1191"
        >
          <v-img
            style="max-width: 100%"
            src="../assets/Scott Pilgrim poster.png"
          />
        </a>
        <p class="whiteText centerText">Scott Pilgrim Quotes</p>
      </v-col>
      <v-col cols="5" md="5" lg="3" xl="3">
        <a
          href="https://popmystic.com/movieDetails?film=tt2911666&phrases=tt2911666-150,tt2911666-588,tt2911666-186,tt2911666-139,tt2911666-145,tt2911666-176,tt2911666-177,tt2911666-178,tt2911666-466"
        >
          <v-img style="max-width: 100%" src="../assets/John Wick poster.png" />
        </a>
        <p class="whiteText centerText">John Wick Quotes</p>
      </v-col>
      <v-col cols="5" md="5" lg="3" xl="3">
        <a
          href="https://popmystic.com/movieDetails?film=tt3228774&phrases=tt3228774-40,tt3228774-945,tt3228774-1830,tt3228774-6,tt3228774-961,tt3228774-728,tt3228774-1573,tt3228774-805"
        >
          <v-img style="max-width: 100%" src="../assets/Cruella poster.png" />
        </a>
        <p class="whiteText centerText">Cruella Quotes</p>
      </v-col>
      <v-col cols="5" md="5" lg="3" xl="3">
        <a
          href="https://popmystic.com/movieDetails?film=tt8806266&phrases=tt8806266-78,tt8806266-119,tt8806266-65,tt8806266-199,tt8806266-477,tt8806266-656,tt8806266-208"
        >
          <v-img style="max-width: 100%" src="../assets/Euphoria poster.png" />
        </a>
        <p class="whiteText centerText">Euphoria Quotes</p>
      </v-col>
      <v-col cols="5" md="5" lg="3" xl="3">
        <a
          href="https://popmystic.com/searchResults?phrase=superman&title=superman&mediaType=both"
        >
          <v-img style="max-width: 100%" src="../assets/Superman poster.png" />
        </a>
        <p class="whiteText centerText">Superman Quotes</p>
      </v-col>
    </v-row>
    <hr />
    <h2>Examples of Research You Can Do with Pop Mystic</h2>
    <br />

    <v-row align="start" justify="center">
      <v-col cols="1" />
      <v-col cols="5">
        <a
          target="_blank"
          href="https://medium.com/@johne_48918/the-merry-men-of-tv-action-levity-utterances-in-knight-rider-and-the-six-million-dollar-man-c3236d787bab"
        >
          <v-img
            class="imageCentered"
            max-width="100%"
            src="../assets/six million sasquatch.png"
          />
        </a>
        <p class="whiteText centerText">
          Laughter Utterances in Action Adventure TV: 70's vs 80's
        </p>
      </v-col>
      <v-col cols="5">
        <a
          target="_blank"
          href="https://medium.com/@johne_48918/the-fusions-of-food-wars-cultural-reflexivity-in-foodie-anime-958d680f415a"
        >
          <v-img
            class="imageCentered"
            max-width="100%"
            src="../assets/food wars sized.png"
          />
        </a>
        <p class="whiteText centerText">
          Food Wars! Cultural Reflexivity in Foodie Anime
        </p>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <hr />
    <h2>Film & Television Citation Guides</h2>
    <br />
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <v-card>
          <v-card-title> Guides for Citing Movies</v-card-title>
          <v-list>
            <v-list-item to="/apa/movies">
              <a>APA</a>
            </v-list-item>
            <v-list-item to="/mla/movies">
              <a>MLA</a>
            </v-list-item>
            <v-list-item to="/chicago/movies">
              <a>Chicago</a>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <v-card>
          <v-card-title> Guides for Citing TV</v-card-title>

          <v-list>
            <v-list-item to="/apa/tv">
              <a>APA</a>
            </v-list-item>
            <v-list-item to="/mla/tv">
              <a>MLA</a>
            </v-list-item>
            <v-list-item to="/chicago/tv">
              <a>Chicago</a>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LandingPage",

  data: () => ({
    dateRules: [
      (value) =>
        !value ||
        (value && value > 1800 && value < 2100) ||
        "Enter year in YYYY format",
    ],
    staticLinks: [
      {
        decade: "2020s",
        entries: [
          {
            text: "Dune quotes",
            routerLink:
              "searchResults?phrase=fear%20see&title=dune&startYear=2020&endYear=2021",
          },
          {
            text: "Black Widow quotes",
            routerLink:
              "searchResults?phrase=do%20what&title=widow&startYear=2021&endYear=2021",
          },
          {
            text: "Tenet quotes",
            routerLink: "searchResults?phrase=I%27ll%20see%20you&title=tenet",
          },
          {
            text: "Wonder Woman 1984 quotes",
            routerLink:
              "searchResults?phrase=all%20I%20see%20is%20you&title=1984&startYear=2020&endYear=2020",
          },
          {
            text: "Scoob! quotes",
            routerLink:
              "searchResults?phrase=dude%20like%20scoob&title=scoob&startYear=2020&endYear=2020",
          },
        ],
      },
      {
        decade: "2010s",
        entries: [
          {
            text: "The Other Guys quotes",
            routerLink:
              "searchResults?phrase=let%20me%20fly&title=guys&startYear=2010&endYear=2010",
          },
          {
            text: "Spring Breakers quotes",
            routerLink:
              "searchResults?phrase=spring%20broke&title=breakers&startYear=2013&endYear=2013",
          },
          {
            text: "Warrior quotes",
            routerLink:
              "searchResults?phrase=what%20we%20have&title=warrior&startYear=2011&endYear=2011",
          },
          {
            text: "John Wick quotes",
            routerLink:
              "searchResults?phrase=Vengeance%20Is%20All&title=wick&startYear=2014&endYear=2014",
          },
          {
            text: "Black Panther quotes",
            routerLink:
              "searchResults?phrase=You%20Would%20Take&title=panther&startYear=2018&endYear=2018",
          },
        ],
      },
      {
        decade: "2000s",
        entries: [
          {
            text: "Mean Girls quotes",
            routerLink:
              "searchResults?phrase=pink%20book&title=mean&startYear=2004&endYear=2004",
          },
          {
            text: "Kill Bill quotes",
            routerLink:
              "searchResults?phrase=revenge%20bill&title=bill&startYear=2003&endYear=2003",
          },
          {
            text: "White Chicks quotes",
            routerLink:
              "searchResults?phrase=you&title=chicks&startYear=2004&endYear=2004",
          },
          {
            text: "Gladiator quotes",
            routerLink:
              "searchResults?phrase=what%20we%20do&title=gladiator&startYear=2000&endYear=2000",
          },
          {
            text: "Avatar quotes",
            routerLink:
              "searchResults?phrase=i%20see%20you&title=avatar&startYear=2009&endYear=2009",
          },
        ],
      },
      {
        decade: "1990s",
        entries: [
          {
            text: "A Few Good Men quotes",
            routerLink:
              "searchResults?phrase=ordered%20handle&title=few&startYear=1992&endYear=1992",
          },
          {
            text: "Glengarry Glen Ross quotes",
            routerLink:
              "searchResults?phrase=need%20balls%20new&title=Glengarry&startYear=1992&endYear=1992",
          },
          {
            text: "Jurassic Park quotes",
            routerLink:
              "searchResults?phrase=should%20could&title=jurassic&startYear=1993&endYear=1993",
          },
          {
            text: "Austin Powers quotes",
            routerLink:
              "searchResults?phrase=the%20last%20man&title=austin&startYear=1997&endYear=1997",
          },
          {
            text: "Braveheart quotes",
            routerLink:
              "searchResults?phrase=man&title=braveheart&startYear=1995&endYear=1995",
          },
        ],
      },
      {
        decade: "1980s",
        entries: [
          {
            text: "Airplane quotes",
            routerLink:
              "searchResults?phrase=good%20have&title=airplane&startYear=1980&endYear=1980",
          },
          {
            text: "The Shining quotes",
            routerLink:
              "searchResults?phrase=gonna%20hurt%20ya&title=shining&startYear=1980&endYear=1980",
          },
          {
            text: "Scarface quotes",
            routerLink:
              "searchResults?phrase=money%20women%20power&title=scarface&startYear=1983&endYear=1983",
          },
          {
            text: "Top Gun quotes",
            routerLink:
              "searchResults?phrase=fly%20dangerous&title=gun&startYear=1986&endYear=1986",
          },
          {
            text: "When Harry Met Sally quotes",
            routerLink:
              "searchResults?phrase=have%20what%20friends&title=sally&startYear=1989&endYear=1989",
          },
        ],
      },
      {
        decade: "1970s",
        entries: [
          {
            text: "Apocalypse Now quotes",
            routerLink:
              "searchResults?phrase=murder%20river&title=apocalypse&startYear=1979&endYear=1979",
          },
          {
            text: "Rocky quotes",
            routerLink:
              "searchResults?phrase=it%27s%20me%20rocky&title=rocky&startYear=1976&endYear=1976",
          },
          {
            text: "Jaws quotes",
            routerLink:
              "searchResults?phrase=bigger%20boat%20beaches&title=jaws&startYear=1975&endYear=1975",
          },
          {
            text: "Taxi Driver quotes ",
            routerLink:
              "searchResults?phrase=talk%20you%20me&title=taxi&startYear=1976&endYear=1976",
          },
          {
            text: "Blazing Saddles quotes",
            routerLink:
              "searchResults?phrase=go%20back%20for&title=blazing&startYear=1974&endYear=1974",
          },
        ],
      },
      {
        decade: "1960s",
        entries: [
          {
            text: "Breakfast at Tiffany’s quotes",
            routerLink:
              "searchResults?phrase=do%20late&title=tiffany%27s&startYear=1961&endYear=1961",
          },
          {
            text: "James Bond quotes",
            routerLink:
              "searchResults?phrase=bond&title=no%20with%20goldfinger%20thunderball%20twice&startYear=1960&endYear=1967",
          },
          {
            text: "Cleopatra quotes",
            routerLink:
              "searchResults?phrase=my%20into%20yours&title=Cleopatra&startYear=1963&endYear=1963",
          },
          {
            text: "Psycho quotes",
            routerLink:
              "searchResults?phrase=fine%20motel&title=psycho&startYear=1960&endYear=1960",
          },
          {
            text: "Mary Poppins Quotes",
            routerLink:
              "searchResults?phrase=what%20do%20you&title=Mary%20Poppins&startYear=1965&endYear=1965",
          },
        ],
      },
      {
        decade: "1950s",
        entries: [
          {
            text: "Godzilla quotes",
            routerLink:
              "searchResults?phrase=look%20out%20there&title=godzilla&startYear=1956&endYear=1956",
          },
          {
            text: "East of Eden quotes",
            routerLink:
              "searchResults?phrase=away%20from%20he&title=Eden&startYear=1955&endYear=1955",
          },
          {
            text: "Cinderella quotes",
            routerLink:
              "searchResults?phrase=love%20find%20him&title=Cinderella&startYear=1950&endYear=1950",
          },
          {
            text: "House of Wax 1953",
            routerLink:
              "searchResults?phrase=house%20into&title=wax&startYear=1953&endYear=1953",
          },
          {
            text: "Sabrina 1954 ",
            routerLink:
              "searchResults?phrase=she%20grew%20beautiful&title=sabrina&startYear=1954&endYear=1954",
          },
        ],
      },
      {
        decade: "1940s",
        entries: [
          {
            text: "Gone with the Wind quotes",
            routerLink:
              "searchResults?phrase=she%20isn%27t%20rich%20&title=wind&startYear=1940&endYear=1940",
          },
          {
            text: "The Wolf Man 1941",
            routerLink:
              "searchResults?phrase=killed%20attack&title=wolf&startYear=1940&endYear=1941",
          },
          {
            text: "Arsenic and Old Lace",
            routerLink:
              "searchResults?phrase=her%20she%20he&title=Arsenic&startYear=1944&endYear=1944",
          },
          {
            text: "The Little Foxes",
            routerLink:
              "searchResults?phrase=pretty%20girls%20bad%20together&title=foxes&startYear=1941&endYear=1941",
          },
          {
            text: "It’s a Wonderful Life quotes",
            routerLink:
              "searchResults?phrase=hers%20his%20christmas&title=wonderful&startYear=1947&endYear=1947",
          },
        ],
      },
    ],
    genres: [],
  }),

  methods: {
    performSearch() {
      this.overlay = true;
      let routerQuery = {
        phrase: this.searchParams.searchPhrase,
      };
      this.searchParams.title
        ? (routerQuery.title = this.searchParams.title)
        : "";
      this.searchParams.keyPerson
        ? (routerQuery.keyPerson = this.searchParams.keyPerson)
        : "";
      this.searchParams.selectedGenres
        ? (routerQuery.genres = this.searchParams.selectedGenres.join())
        : "";
      this.searchParams.startYear
        ? (routerQuery.startYear = this.searchParams.startYear)
        : "";
      this.searchParams.endYear
        ? (routerQuery.endYear = this.searchParams.endYear)
        : "";
      this.searchParams.mediaType
        ? (routerQuery.mediaType = this.searchParams.mediaType)
        : "";
      this.$router.push({
        path: "/searchResults",
        query: routerQuery,
      });
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    ...mapState({
      searchParams: "searchParams",
      loading: "loading",
    }),
  },
  mounted() {
    this.genres = [
      "Action",
      "Adventure",
      "Animation",
      "Biography",
      "Comedy",
      "Crime",
      "Documentary",
      "Drama",
      "Family",
      "History",
      "Horror",
      "Musical",
      "Mystery",
      "Reality-TV",
      "Romance",
      "Sci-Fi",
      "Sport",
      "Thriller",
      "Western",
    ];
    this.$store.dispatch("clearSearchResults");
  },
};
</script>

<style scoped>
hr {
  border: none;
  height: 0.5px;
  background-color: white;
  margin-top: 2em;
  margin-bottom: 2em;
}

h2 {
  text-align: center;
  color: white;
  font-style: normal;
}

p.whiteText {
  color: white;
  margin-bottom: 0;
}

p.centerText {
  text-align: center;
}

.imageCentered {
  max-width: 50%;
  margin: auto;
}

.searchData {
  background: white;
  opacity: 0.85;
}

.v-application .primary--text {
  color: #5b5ea6 !important;
}

.v-application a {
  color: #5b5ea6 !important;
}

.whiteRadio /deep/ label {
  color: white !important;
}

.whiteRadio /deep/ i {
  color: white !important;
}

.advancedSearchFilters {
  padding-top: 0;
  padding-bottom: 0;
}

.v-select-list,
.v-list,
.v-list-item,
.v-list-item--link,
.v-select__selection--comma {
  background: #ebe8f3;
}

.v-sheet {
  background: #ebe8f3 !important;
}

.quoteContent {
  padding: 24px;
  background-color: white;
  border-radius: 6px;
}
</style>
